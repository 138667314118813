
  


/*------------------------------------COLOR CODE-------------------------------------------*/
export const primary = '#1A2754';
export const secondary = '#1A2754';
export const appBackground='#F9F9F9';
export const searchPrimary='#fff';
export const searchSecondary = '#fff';
export const searchIconColor = '#f99d1e';

export const tertiary = '#fff';
export const textColor = "#212121";
export const danger = 'red';
export const green = '#4cd137';
export const blueColor = '#3B5998';
export const timeLineNotCompleted = '#dcdde1';
export const devileredStatus = '#44bd32';
export const red = '#D2042D';

/*------------------------------------COMPANY ID-------------------------------------------*/

export const type = 0; 
export const companyidValue = 46;
export const appstoreId = "id1550483770";
export const playstoreId = "com.fishandmeat.catalogue";
export const mobileChar = 10;

/*------------------------------------STORE NAME-------------------------------------------*/
export const storeName="fishandmeat";

export const aboutProfileLable = "About Us"
export const aboutIconMe = "me";

export const contactUsLabel = "Contact Us"

// tab bar
export const homeLabel = 'Home'
export const categoriesLabel = 'Categories'
export const offersLabel = 'Offers'
export const profileLabel = 'Profile'




// root header
export const exitAppText = 'Press back again to exit the app';
export const searchLabel = 'Search';
export const errorToast = "Something went wrong!";
export const noNetwork = "No Internet Connection";
export const loginForFav = "Please login to proceed !";
export const searchButtonLabel = 'Search';
// choose store
export const chooseStoreLabel = 'Choose A Store';

// home page
export const featuredLabel = "Featured";
export const bestSellerLabel = 'Best Seller';
export const brandStore = "Brand Store";
export const loadMoreButtonLabel = 'LOAD MORE';
export const previousButtonLabel = '<- Previous';
export const nexButtonLabel = 'Next ->';
export const categoryLabel = 'Categories';
export const shopCategoryLabel = 'Shop by Categories';
export const viewAll = 'View All';
export const shopBrandsLabel = 'Shop by Brands';


// product listing
export const addedToFavLabel = 'Product added to favourite. Please login to view the favourite list';
export const removedFromFavLabel = 'Product removed from favourite. Please login to view the favourite list';
export const addToCartLabel = 'Add to cart';
export const prodInfoLabel = 'Product Info';
export const ratingLabel = 'Rating';
export const specificationLabel = 'Specification';
export const offerTermsLabel = 'Offer Info';
export const pro_aboutLabel = 'About Info ';
export const pro_detailedAboutLabel = 'Detailed Specs'
export const addToBagLabel = 'Add to cart';
export const buyNowLabel = "Buy Now";
export const noPreviewImages= "No Preview";
export const bookNowLabel = "Book Now";

// profile
export const addressLabel = "Address";
export const myProfileHeader = 'My Profile';
export const userloginLabel = "Salesman Login"
export const loginFirstText = "Please login to access customized "
export const loginSecondText = "offers and your wish-list"
export const loginLabel = "Login";
export const signupLabel = "Sign Up";
export const credentialLabel = "Your Credentials";
export const enterPhoneLabel = "Enter Phone Number";
export const countryCode = "+91";
export const termsLabel = "Terms & Conditions";
export const faqLabel = "FAQ";
export const supportLabel = "Support";
export const enterOptLabel = "Enter OTP";
export const optSecondLabel = "Waiting For 6 Digit OTP";
export const getOtpLabel = "Get OTP";
// signUp 
export const nameLabel = "Name";
export const mobileLabel = "Mobile";
export const emailLabel = "Email";
export const passwordLabel = "Password";
export const confirmPasswordLabel = "Confirm Password";
export const weakPasswordLabel = 'Weak Passowrd';
export const closeLabel = 'Close';
export const stronPwdLabel = 'Strong Password';
export const pwdSpecLabel ='Password Specification';
export const pwdSpecOneLabel = "Password must have minimum of 8 charatcters.";
export const pwdSpecTwoLabel = "Password must contain atleast 1 Capital letter.";
export const pwdSpecThreeLabel = "Password must contain atleast 1 Special charatcter.";
export const pwdSpecFourLabel = "Password must contain atleast 1 numeric character.";
export const termsSignupLabel = "Terms";
export const faqSignupLabel = "FAQ";
export const supportSignupLabel = "Support";


// offer screen
export const offers = "Offers";
export const coupansLabel = "Coupans";





// filter
export const filterLable = 'Filter';
export const refineByLable = "Refine By";
export const sortByLable = "Sort By";
export const clearallLabel = "Clear All"
export const doneLabel = "Done"


// cart
export const wishListLabel = "Wish List"
export const removeLabel = "Remove";
export const delivetToLabel = "Deliver To"
export const noWishData = "No WishList Avaliable";
export const removefromCart = "Remove Item";
export const noItmsLabe = "No Items In Cart";
export const outOfStockMessage = 'Please remove out of stock items from the cart to proceed';
export const cartAddRemove = 'Stock not avaliable';
export const outOfStockLabel = 'Out of stock';
export const anotherBranch = 'Cart contain products from another branch. Please clear it first';
export const removedFromCart = 'Removed from cart';
export const itemAddedTocart = 'Added successfully';
export const itemReducedFromCart = "Reduced successfully";
export const chooseDeliveyAddressLabel = 'Choose Delivery Address';
export const priceFilterApplied = 'Price filter applied';
export const inStockLabel = 'In-Stock';
export const outStockLabel = 'Out of stock';

// order summary
export const deliveryTypeLabel = 'Delivery Type';
export const paymentModeLabel = 'Payment Modes';
export const placeorderLabel = 'Place Order';
export const payLabel = 'PAY';
export const deliveryNotAvailable = 'Delivery not available to this area';
export const deliveryNotAvailableMessage = 'Please login and configure delivery address to know whether delivery available or not';
export const deliverySlotLabel = 'Delivery Slot';


// profile
export const profileOrderDesLabel = "Manage Your Orders"
export const faqDesLabel = "Frequently Asked Questions"
export const aboutDesLabel = "Get To Know About Us"
export const termsProfLabel = "Terms & Conditions"
export const contactDesLabel = "Contact Support";
export const logoutLabel = "Logout"
export const logoutDesLabel = "Logout"
export const resetPasswordLabel = "Reset Password";
export const resetPasswordDesLabel = "Manage Your Password";
export const oldPasswordLabel = "Old Password";
export const newPasswordLabel = "New Password";
export const changeMobileLabel = "Change Mobile";
export const changeMobileDesLabel = "Manage Your MobileNo";
export const refundPolicyProfLabel = "Refund Policy"
export const cancelPolicyProfLabel = "Cancellation Policy"

// profile inner
export const manageAddressHeaderLabel = 'Manage Address';
export const defaultAddressLabel = "Default Address";
export const otherAddressLabel = "Other Addresses";
export const addNewAddressLabel = 'Add New Address';
export const noAddressLabel = 'No Address Found.';
export const addAddressLabel = "Please Add Address"
// add address
export const addNewButtonTitle = 'Add New';
export const saveButtonLabel = 'Save';
export const addNameLabel = 'Add New Name';
export const addNamePlaceholder = "Optional (neighbour or collegue)"
export const addressTypeLabel = 'Address Type';
export const cityPinLabel = "City/Pincode";
export const pinPlaceholder = 'Enter Pincode';
export const enterAddressLabel = 'Landmark';
export const enterAddressPlaceholder = 'Enter Landmark';
export const alterMobile = "Alternate Mobile No";
export const alterMobilPlaceholder = "Enter Alternate Mobile No";
export const houseNoLabel = 'House Name / Number';
export const housePlaceholder = "Enter House Name / Number";
export const areaLabel = 'Area';
export const areaPlaceholder = "Enter Area";
export const cityLabel = 'City';
export const cityPlaceholder = "Enter City";
export const stateLabel = "State";
export const statePlaceholder = "Enter State";
export const countryLabel = "Country";
export const countryPlaceholder = "Enter Country";
export const chooseButtonLabel = 'Set Location';

// my order screen
export const myOrderHeaderLabel = 'My Orders';
export const currentOrderLabel = "Current Orders";
export const deliveredOrderLabel = "Delivered Orders";
export const trackButtonLabel = 'Track';
export const cancelLabel = 'Cancel';
export const returnLabel = 'Return';
export const reOrederLabel = 'Re-Order';
export const onProgressLabel = "On Progress";
export const deliveredLabel = 'Delivered';
export const cancelledLabel = 'Cancelled';
export const returnedLabel = 'Returned';
export const acceptedLabel = 'Accepted';
export const PENDING = "PENDING";
export const ORDERED = "ORDERED";
export const ACCEPTED = "ACCEPTED";
export const PREPARING = "PREPARING";
export const PACKED = "PACKED";
export const SHIPPED = "SHIPPED";
export const OUT_FOR_DELIVERY = "OUT FOR DELIVERY";
export const DELIVERED = "DELIVERED";
export const CANCEL = "CANCEL";
export const CANCELLED = "CANCELLED";
export const RETURN = "RETURN";
export const RETURNED = "RETURNED";
export const REFUND_INITIATED = "REFUND INITIATED";
export const REFUNDED = "REFUNDED";
export const cancelItmesHeder = "Cancel Item";

// track order
export const trackOrderHeaderLabel = 'Order-Details';
export const deliveryStatusLabel = 'Delivery Status';
export const expectedReachLabel = 'Expected to Reach you by';
export const deliverdLabelOn = 'Your item has been delivered on'
export const paymentInfoLabel = 'Payment-Info';
export const itemLabel = 'Item Total';
export const discountLabel = "Discount";
export const gstLabel = "Tax";
export const shippingChagreLabel = "Shipping Charges";
export const netPayableLabel = "Net Payable";
export const paymentMode = "Cash On Delivery";
export const changeLabel = "Change";

// cancel order
export const cancelOrderHeaderLabel = "Cancel-Item";
export const reasonForCancelLabel = "Reason For Cancel";
export const helpImproveLabel = "Help Us To Improve"; 
export const helpImprovePlaceholder = "Describe in detail(Optional)"
export const cancelOrderButtonLabel = "Cancel Order";

// return product
export const returnProductHeaderLabel = 'Return-Item';
export const returnEligibilityLabel = "Your product is eligible for";
export const returnDayLabel = "day return policy";
export const reasonForReturnLabel = "Reason For Return";
export const describeIssueLabel = "Describe Your Issue"; 
export const showIssueLabel = "Show us Your Issue";
export const showPhotoPlaceholder = "Select Photos...";
export const uploadLabel = "Upload";
export const returnItemButtonLabel = "Request Return";

// forgot password
export const forgotLabel = "Forgot Password";
export const getOptlabel = "Get OTP"

// manage address
export const manageAddressLable = "My Address";
export const manageAddressDesLable = "Manage Your Address";

// my favourite
export const wishListDes = "Manage Wish List";
// notification
export const notificationHeaderLabel = "Notifications";
export const notifyDes = "Manage Your Noticications";
export const notifyHeadDes = "Don't miss out Best Deals";
// faq
export const faqHeading = 'Lets Clear Some Doubts';
export const topQuestionLabel = 'Top Questions';
export const paymentTabLabel ='Payment';
export const returnsLabel = 'Returns';
export const deliveryTabLabel = 'Delivery';
export const productsLabel = 'Products';

// help & support
export const helpSupportLabel = "Help & Support";
export const suportheadingLabel = "Need Help Don't hesitate to call or write";
export const callUsLabel = "Call Us(24x7)";
export const writeLabel = "Write Us";
export const emailexpoectLabel = "(Expect Instant Reply)";
export const followLabel = "Follow Us"
export const facebook = "Facebook";
export const instagram = "Instagram";

// review and ratings
export const raingReviewHeader = "My Rating & Reviews";
export const profileRatingDes = 'Manage Your Ratings & Reviews';
export const rateItemButtonLabel = "Rate Item";
export const ratingDes = 'Rate Your Experiance';
export const rateItemLabel = "Rate Item";
export const qualityProductLabel = "Quality of the Product";
export const freshnessLabel = "Freshness";
export const quantityLabel = "Qty";
export const showWorldExpLabel = "Show the world Your Experiance";
export const photoPlaceholder = "Photos selected";
export const writeDetailedReviewLabel = "Write a Detailed Review";

// order
export const orderSummaryLabel = "Order Summary";
export const deliveryChargeLabel = "Delivery Charges";
export const roundOffLabel = "Round Off";

// profile edit
export const profileEdit = 'Manage Profile';
export const countryChooseLabel = "Choose country";

//profile edit screen
export const profileName = 'Name';

//change mobile
export const currentMobileNo = 'Current Mobile Number';

//contact us
export const contactUsNameLabel = 'Name';
export const contactUsEmailLabel = 'Email';
export const contactUsPhoneLabel = 'Phone';
export const contactUsCommentLabel = 'Comments';
export const contactUsSaveButtonLabel = 'Submit';

export const updateApp="Update";

// API
export const companyIdHeader = 'CompanyId';
// export const domainUrl = 'com.catalogue.chittilappilly/';

// export const domainUrl = 'https://catalogueapi.estorelogin.com/';
export const domainUrl = 'https://api.xeniacatalogue.info/';
// export const domainUrl = 'https://menuapi.estorelogin.com/';
// export const domainUrl = 'https://api.estorelogin.com/';
// export const domainUrl = 'http://testapi.estorelogin.com/'

// branched
// export const branchUrl = 'api/Branch/Online';
export const companyInfoUrl = 'api/Company/Info';
export const branchUrl = 'api/Branch';

// registration
export const optRegistration = 'api/OTP/Registration?mobileNo=';
export const emailOtp = '&email=';
export const registerAPI = 'api/Customer/Register?name=';
export const registeMobile = '&mobileNo=';
export const registeremail = '&email=';
export const registerpassword = '&password=';
export const registerOtp = '&otpCode=';

//login OTP
export const loginEmailOTP = "api/OTP/LoginWithEmail";
export const verifyOTP = "api/OTP/ValidateOTP";
export const otpMobile = '?mobileNo=';
export const otpEmail = '&email=';
export const otpCustomer = '&CustomerName=';
export const otpCompanyID = '&companyId=';
export const otpCode = '&otpCode=';

// login
export const loginUrl = "token";
export const loginContentUrl = 'grant_type=password&username=';
export const companyidUrl = '&companyId=';
export const getCustomerInfo = 'api/Customer/info';
export const updateCustomerInfo = "api/Customer/Update";


// forgot password
export const forgotOTPUrl = 'api/OTP/ForgotPassword?mobileNo=';
export const forgotPwdReset = 'api/Customer/ForgotPassword?mobileNo=';


// reset password
export const resetPwdUrl = 'api/Customer/ResetPassword?customerId=';
export const oldPwdUrl = '&oldPassword=';
export const newPwdUrl = '&newPassword=';

// home offers banner
export const offersBannerUrl = 'api/OfferSettings/Banners';
export const offersUrl = 'api/OfferSettings';
export const offerDetailsUrl = 'api/OfferSettings/';
export const offerProductsUrl= 'api/OfferSettings/Products'
// category
export const getCategories = 'api/Categories/Tree';
export const getCategoryHomeUrl = 'api/Categories/Home';
export const getCategoryHomeExtUrl = 'api/Categories/Home/Ext';
// category products
export const getCategoryProducts = 'api/Product/Search';
export const getCategoryPaginatedProducts = 'api/Product/PagedSearch';
export const getDailyUpdatedProducts = 'api/Product/DailyUpdatedProducts';


// cart
export const getCartCountUrl = 'api/CustomerCarts/Count';
export const addToCartUrl = 'api/CustomerCarts/Add?productId=';
export const cartAddQntUrl = '&qty=';
export const varientIdUrl = '&variantId='
export const reduceQtyUrl = 'api/CustomerCarts/Reduce?productId=';
export const removeFromCartUrl = 'api/CustomerCarts/Remove?productId=';
export const getCartListUrl = 'api/CustomerCarts';
export const postLocalItemsUrl = 'api/CustomerCarts/AddItems';

// product fav
export const getFaveUrl = "api/Favourites";
export const addFavUrl = "api/Favourites/Add?productId=";
export const removeFavUrl = "api/Favourites/Remove?productId=";

// brands
export const getBrands = 'api/Brands';
export const getHomeBrands = 'api/Brands/Home';
// brand Products
export const getBrandProducts = 'api/BrandsWithProducts/';

// get special category
export const getSpecialCategory = 'api/SpecialCategories';

// get product details
export const getProductDetails = 'api/Products/';

// get filters
export const getFilterUrl = 'api/Product/Filters?categoryId='
export const filterBrandUrl = "&brandId=";


// manage address
export const getAddressUrl = 'api/CustomerAddresses';
export const getSingleAddress = 'api/CustomerAddresses?id=';
export const updateAddress = 'api/CustomerAddresses';
export const addNewAddress = "api/CustomerAddresses";
export const removeAddress = 'api/CustomerAddresses?id=';
// static pages
// about us
export const getAboutUrl = 'api/Company/Aboutus';

// terms and condition
export const getTermsUrl = 'api/Company/TermsAndPolicy';

// support info
export const getSupportUrl = 'api/Company/SupportInfo';
export const contactUsUrl = 'api/Company/ContactUs'

// faq info
export const getFAQUrl = 'api/FAQSettings';


// order
// set delivery address
export const setDeliverAddressUrl = "api/Order/SetDeliveryAddress?customerAddressId=";

// order summary
export const orderSummaryUrl = "api/Order/Summary";
export const paymetProviderUrl = "api/PaymentProviders/All";
export const deliveryTypeUrl = "api/DeliveryTypes";
export const setDeliveryTypeUrl = "api/Order/SetDeliveryType?";
export const deliverySlotUrl = "api/DeliveryTimeSlots";

// place order
export const placeOrderUrl = 'api/Order/PlaceOrder';
export const placeOrderClaimUrl = 'api/Order/PlaceOrderWithClaim';
// orders
export const myOrderUrl = 'api/Orders';
export const orderDetails = 'api/Orders?id=';
export const cancelOrder = 'api/Order/Cancel';
export const reasonFroCancelUrl = 'api/Reasons/Cancel'; 
export const returnReasonUrl = 'api/Reasons/Return';
export const returnOrderUrl = 'api/Order/Return';
export const reOrderUrl = 'api/Order/Reorder?orderId=';
// faq
export const faqUrl = 'api/FAQSettings';

// countries
export const countryUrl = 'api/Country';

// change mobile
export const changeMobileOTPUrl = 'api/OTP/ChangeMobileNumber?';
export const changeMobileUrl = 'api/Customer/ChangeMobile?';


export const getRefundPolicyUrl = 'api/Company/RefundPolicy';
export const getCancellationPolicyUrl = 'api/Company/CancellationPolicy';
export const postEnquiryUrl = 'api/Enquiry/Save';
export const versionCheck='api/Company/AppVersion';

// create razorpay order
export const createRazorPayOrderUrl = 'api/RazorPay/CreateOrder?';

//
export const existingUserDataUrl = 'api/Customer/CustomerValidation?mobileNo='
